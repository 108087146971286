import { useContext } from "react";

import Fade from "../components/Fade";
import StyledButton from "../components/StyledButton";
import { AppContext, MenuScreen } from "../App";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { faLock } from "@fortawesome/free-solid-svg-icons/faLock";
import { faLanguage } from "@fortawesome/free-solid-svg-icons/faLanguage";
import { faBuilding } from "@fortawesome/free-solid-svg-icons/faBuilding";
import { faGear } from "@fortawesome/free-solid-svg-icons/faGear";

import StyledContentPanel from "../components/StyledContentPanel";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import { useTranslation } from "react-i18next";

const MainMenu: React.FC = () => {
    const { t } = useTranslation();

    const { setMenuScreen } = useContext(AppContext);

    const aboutUsClicked = () => {
        window.open('https://www.cnts.bg/', '_blank', 'noopener,noreferrer');
    };

    return (
        <div style={{ padding: '20px' }}>
            <Fade cascade>
                <StyledContentPanel style={{ marginBottom: '20px' }}>
                    <span style={{ marginRight: '10px' }}><FontAwesomeIcon icon={faGear} /></span>
                    {t('menu_main_title')}
                </StyledContentPanel>
                <StyledButton onClick={() => setMenuScreen(MenuScreen.None)}>
                    <span style={{ marginRight: '10px' }}><FontAwesomeIcon icon={faArrowLeft} /></span>
                    {t('button_back')}
                </StyledButton>
                <StyledButton onClick={() => setMenuScreen(MenuScreen.Profile)}>
                    <span style={{ marginRight: '10px' }}><FontAwesomeIcon icon={faUser} /></span>
                    {t('menu_main_profile')}
                </StyledButton>
                <StyledButton onClick={aboutUsClicked}>
                    <span style={{ marginRight: '10px' }}><FontAwesomeIcon icon={faBuilding} /></span>
                    {t('menu_main_about_us')}
                </StyledButton>
                <StyledButton onClick={() => setMenuScreen(MenuScreen.Language)}>
                    <span style={{ marginRight: '10px' }}><FontAwesomeIcon icon={faLanguage} /></span>
                    {t('menu_main_language')}
                </StyledButton>
                <StyledButton onClick={() => setMenuScreen(MenuScreen.PrivacyNoticeMenu)}>
                    <span style={{ marginRight: '10px' }}><FontAwesomeIcon icon={faLock} /></span>
                    {t('menu_main_privacy_notice')}
                </StyledButton>
            </Fade>
        </div>
    );
};

export default MainMenu;