
import React, { useContext, useEffect, useState } from 'react';

import axios from 'axios';

import { AppContext, MenuScreen, ProfileData } from '../App';

import styled from 'styled-components';
import StyledContentPanel from '../components/StyledContentPanel';
import StyledButton from '../components/StyledButton';
import StyledDropdown from '../components/StyledDropdown';
import StyledTextInput from '../components/StyledTextInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';
import Fade from '../components/Fade';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { capitalizeFirst } from '../util/util';

const ProfileDropDown = styled(StyledDropdown)`
    margin-bottom: 20px;
    background: #0006;
    padding: 21px 28px;
`;

const ProfileStyle = styled.div`
    padding: 20px;
`;

const ProfileDivider = styled.hr`
    margin: 40px 0;
    height: 3px;
    border: none;
    background-color: #00000055;
`;

interface ProfileFormData {
    locale: string;
    name: string;
    gender: 'male' | 'female' | 'other' | null;
    birthYear: number | null;
    cityName: string;
    regionName: string;
    countryName: string;
}

interface ProfileScreenState {
    formChanged: boolean;
    formData: ProfileFormData;
}

const ProfileComponent: React.FC = () => {
    const { t } = useTranslation();

    const { setMenuScreen } = useContext(AppContext);

    const initFormData = (profile: ProfileData) => ({
        locale: profile.locale,
        name: profile.name ?? '',
        gender: profile.gender,
        birthYear: profile.birthYear,
        cityName: profile.cityName,
        regionName: profile.regionName,
        countryName: profile.countryName
    });

    const { apiUrl, profile, setProfile, topic } = useContext(AppContext);
    const [state, setState] = useState<ProfileScreenState>({
        formChanged: false,
        formData: initFormData(profile)
    });

    useEffect(() => {
        setState({ ...state, formData: { ...state.formData, birthYear: profile.birthYear } });
    }, [profile.birthYear]);

    useEffect(() => {
        setState({ ...state, formData: { ...state.formData, gender: profile.gender } });
    }, [profile.gender]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setState({
            ...state,
            formChanged: true,
            formData: { ...state.formData, [e.target.name]: e.target.value }
        });
    };

    const saveChanges = async () => {
        const fieldsToChange: Record<string, unknown> = {};

        for (const fieldKey of Object.keys(state.formData) as string[]) {
            const formField = state.formData[fieldKey as keyof typeof state.formData];
            const profileField = profile[fieldKey as keyof typeof profile];

            if (formField !== profileField && formField)
                fieldsToChange[fieldKey] = formField;
        }

        if (Object.keys(fieldsToChange).length > 0) {
            if (topic)
                fieldsToChange.topicId = topic.id;
            try {
                await axios.put(`${apiUrl}/user`, fieldsToChange);
                for (const fieldKey of Object.keys(state.formData)) {
                    (profile as any)[fieldKey as keyof typeof profile] = state.formData[fieldKey as keyof typeof state.formData];
                }
                setProfile({ ...profile });
                setState({ ...state, formChanged: false, });

                toast.success(t('menu_profile_toast_success'), {
                    style: {
                        borderRadius: '10px',
                        background: '#666',
                        color: '#fff',
                    },
                });
            } catch (err) {
                toast.error(t('general_error_message'), {
                    style: {
                        borderRadius: '10px',
                        background: '#666',
                        color: '#fff',
                    },
                });
            }
        }
    };

    const cancelChanges = () => {
        toast('Промените са отказани.', {
            style: {
                borderRadius: '10px',
                background: '#666',
                color: '#fff',
            },
        });
        setState({
            formChanged: false,
            formData: initFormData(profile)
        });
    };

    const locales = [
        //{ value: 'en_US', name: 'English' },
        { value: 'bg_BG', name: 'български' },
        /*{ value: 'nl_NL', name: 'Nederlands' },
        { value: 'ru_RU', name: 'Ру́сский' },
        { value: 'hy_AM', name: 'Հայերեն' },
        { value: 'fr_FR', name: 'Français' },
        { value: 'de_DE', name: 'Deutsch' },
        { value: 'es_ES', name: 'Español' },
        { value: 'pt_PT', name: 'Português' },
        { value: 'el_GR', name: 'Ελληνικά' },
        { value: 'hr_HR', name: 'Hrvatski' }*/
    ];

    const genders = [
        { value: 'male', name: capitalizeFirst(t('general_male')) },
        { value: 'female', name: capitalizeFirst(t('general_female')) },
    ];

    const birthYears = Array.from(Array(77).keys()).map(n => n + 1940).map(year =>
        ({ value: year, name: year })
    );

    return (
        !profile ? null :
            <ProfileStyle>
                <Fade cascade>
                    <StyledContentPanel style={{ marginBottom: '20px' }}>
                        <h1>
                            <span style={{ marginRight: '10px' }}><FontAwesomeIcon icon={faUser} /></span>
                            {t('menu_profile_title')}
                        </h1>
                    </StyledContentPanel>
                    <StyledButton onClick={() => setMenuScreen(MenuScreen.MainMenu)}>
                        <span style={{ marginRight: '10px' }}><FontAwesomeIcon icon={faArrowLeft} /></span>
                        {t('button_back')}
                    </StyledButton>
                    <StyledContentPanel>
                        {/*<ProfileDropDown name='locale' onChange={handleChange}>
                            {locales.map(locale =>
                                <option value={locale.value} selected={state.formData.locale === locale.value}>
                                    {locale.name}
                                </option>
                            )}
                        </ProfileDropDown>*/}
                        <StyledTextInput type='text' placeholder={t('input_placeholder_name')} onChange={handleChange} name={'name'} value={state.formData.name} />
                        <ProfileDropDown name='gender' onChange={handleChange}>
                            <option disabled selected={!state.formData.gender}>{t('input_placeholder_gender')}</option>
                            {genders.map(gender =>
                                <option value={gender.value} selected={state.formData.gender === gender.value}>
                                    {gender.name}
                                </option>
                            )}
                        </ProfileDropDown>
                        <ProfileDropDown name='birthYear' onChange={handleChange}>
                            <option disabled selected={!state.formData.birthYear}>{t('input_placeholder_birth_year')}</option>
                            {birthYears.map(birthYear =>
                                <option value={birthYear.value} selected={state.formData.birthYear === birthYear.value}>
                                    {birthYear.name}
                                </option>
                            )}
                        </ProfileDropDown>
                        <StyledTextInput type='text' name='cityName' placeholder={t('input_placeholder_city')} onChange={handleChange} value={state.formData.cityName} />
                        <StyledTextInput type='text' name='regionName' placeholder={t('input_placeholder_region')} onChange={handleChange} value={state.formData.regionName} />
                        <StyledTextInput style={{ marginBottom: 0 }} type='text' name='countryName' placeholder={t('input_placeholder_country')} onChange={handleChange} value={state.formData.countryName} />
                    </StyledContentPanel>
                    <StyledButton style={{ marginTop: '20px' }} disabled={!state.formChanged} onClick={saveChanges}>
                        <span style={{ marginRight: '10px' }}><FontAwesomeIcon icon={faCheck} /></span>
                        {t('menu_profile_button_save')}
                    </StyledButton>
                    <StyledButton disabled={!state.formChanged} onClick={cancelChanges}>
                        <span style={{ marginRight: '10px' }}><FontAwesomeIcon icon={faXmark} /></span>
                        {t('menu_profile_button_discard')}
                    </StyledButton>
                    <ProfileDivider />
                    <StyledButton style={{ backgroundColor: '#ff0000b0', color: 'white' }} onClick={() => setMenuScreen(MenuScreen.DeleteProfileAYS)}>
                        {t('menu_profile_button_delete')}
                    </StyledButton>
                </Fade>
            </ProfileStyle>
    );
};

export default ProfileComponent;