import { useEffect, useRef } from "react";
import styled from "styled-components";

const TypingTextStyle = styled.div`
    //background: red;
    transition: height 1s ease;

    span.visible-part {

    }

    span.transparent-part {
        opacity: 0;
    }
`;

interface TypingTextProps {
    text: string;
    delay?: number;
    active?: boolean;
    onComplete?: () => void;
}

const TypingText: React.FC<TypingTextProps> = (props) => {

    const cursorRef = useRef<number>(0);

    const visiblePartRef = useRef<HTMLSpanElement>(null);
    const transparentPartRef = useRef<HTMLSpanElement>(null);

    const textRef = useRef<string>(props.text);

    const timeoutRef = useRef<NodeJS.Timeout | number | null>(null);

    const typingLoop = () => {
        timeoutRef.current = setTimeout(() => {
            if (cursorRef.current < textRef.current.length) {
                cursorRef.current++;
                
                if(visiblePartRef.current && transparentPartRef.current) {
                    visiblePartRef.current.textContent = textRef.current.slice(0, cursorRef.current);
                    transparentPartRef.current.textContent = textRef.current.slice(cursorRef.current);
                }

                typingLoop();
            } else {
                timeoutRef.current = null;
                if(props.onComplete && typeof props.onComplete === 'function')
                    props.onComplete();
            }
        }, props.delay ?? 20);
    };

    /*useEffect(() => {
        console.log(props.active);
        if(props.active === undefined || props.active === true) {
            typingLoop();
        }
    }, []);*/

    useEffect(() => {
        textRef.current = props.text;
        cursorRef.current = 0;

        if(!timeoutRef.current) {
            if(props.active === undefined || props.active === true) {
                typingLoop();
            }
        }

    }, [props.text]);

    useEffect(() => {
        if(props.active === undefined || props.active === true) {
            typingLoop();
        }
    }, [props.active]);

    if(props.text !== textRef.current) {
        cursorRef.current = 0;
    }

    return (
        <TypingTextStyle>
            <span ref={visiblePartRef} className='visible-part'>{props.text.slice(0, cursorRef.current)}</span>
            <span ref={transparentPartRef} className='transparent-part'>{props.text.slice(cursorRef.current)}</span>
        </TypingTextStyle>
    );
};

export default TypingText;