import { GroupResult } from "@kazvabg/voterapp-model";
import { useEffect, useRef } from "react";
import { animated, useSpring } from "react-spring";
import styled from "styled-components";

const SlideCardContainer = styled.div`
    padding: 10px 0;
    height: 100%;
`;

const SlideCard = styled(animated.div)`
    background-color: #00000055;
    padding: 20px;
    color: white;
    border-radius: 20px;
    display: inline-block;
    font-weight: bold;
    font-size: 24px;
    box-sizing: border-box;
    margin-bottom: 20px;
    width: calc(100% - 20px);
    vertical-align: top;
    height: 100%;
    border: 3px solid transparent;

    h1 {
        margin: 0;
        font-size: 24px;
    }

    p {
        font-size: 20px;
        color: white;
        margin-top: 15px;
        margin-bottom: 0;

        &.auxiliary {
            font-size: 16px;
        }

        @media only screen and (max-width: 400px) {
            font-size: 16px;
        }
    }

    .header-icon-row {
        font-size: 56px;

        .icon {
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }
        }

        @media only screen and (max-width: 450px) {
            font-size: 36px;
        }

        @media only screen and (max-width: 340px) {
            font-size: 24px;
        }
    }
`;

// '#e63939', '#ff4d40', '#c36eff', '#538cff', '#53b7ff', '#1ed931'

const ReferenceRatingLine = styled.div`
    width: 20px;
    height: calc(100% - 40px);
    display: inline-block;
    margin: 20px 0px 0px 0px;

    .container {
        position: relative;
        width: 570px;
        margin-left: -550px;
        height: 10px;
        z-index: -2;
    }

    .large-part {
        height: 100%;
        display: inline-block;
        vertical-align: top;
    }

    .small-part {
        height: 100%;
        display: inline-block;
        vertical-align: top;
    }

    ${(props: { showLine: boolean, lineColor: string }) => `

        .large-part {
            margin-left: ${props.showLine ? 0 : '550px'};
            width: ${props.showLine ? '550px' : 0};
            background-color: ${props.lineColor};
            transition: ${props.showLine
            ? 'width 0.5s linear 0.3s, margin-left 0.5s linear 0.3s'
            : 'width 0.5s linear, margin-left 0.5s linear'
        };
        }

        .small-part {
            margin-left: ${props.showLine ? 0 : '20px'};
            width: ${props.showLine ? '20px' : 0};
            background-color: ${props.lineColor};
            transition: ${props.showLine
            ? 'width 0.3s linear, margin-left 0.3s linear'
            : 'width 0.3s linear 0.5s, margin-left 0.3s linear 0.5s'
        };
        }
    `}

`;

interface ResultSlideProps {
    slideClicked: (slideNumber: number) => void;
    groupResult?: GroupResult;
    currentSlide: number;
    index: number;
    children: React.ReactNode;
    loadingResults: boolean;
}

interface LineData {
    visible: boolean;
    color: string;
    rating: number;
}

const ResultSlide: React.FC<ResultSlideProps> = (props) => {

    let lineData: LineData | null = null;

    if (props.groupResult) {
        lineData = {
            visible: props.index === props.currentSlide && props.groupResult.voteCount !== null,
            color: props.groupResult.color ?? '#000',
            rating: props.groupResult.rating ?? 50
        };
    }

    const lineDataRef = useRef(lineData);
    lineDataRef.current = lineData;

    const currentIndex = useRef(0);
    const reversedRef = useRef(false);

    const getNextColor = () => {
        //const colorArray: string[] = ['#e63939', '#ff4d40', '#c36eff', '#538cff', '#53b7ff', '#1ed931'];
        const colorArray: string[] = ['#e63939', '#c36eff', '#1ed931'];
        const nextColor = colorArray[currentIndex.current];

        if (reversedRef.current) {
            if (currentIndex.current === 0) {
                reversedRef.current = false;
            }
        } else {
            if (currentIndex.current === colorArray.length - 1) {
                reversedRef.current = true;
            }
        }

        if (reversedRef.current) {
            currentIndex.current--;
        } else {
            currentIndex.current++;
        }

        return nextColor;
    };

    const [resultSlideStyle, resultSlideApi] = useSpring(() => ({
        backgroundColor: `#00000055`,
        border: '3px solid transparent',
        config: {
            duration: 550,
            mass: 20,
            tension: 1,
            friction: 180,
            clamp: true
        }
    }));

    const loadingResultsRef = useRef<boolean>(props.loadingResults);

    const fadeToNextColor = () => {
        if (loadingResultsRef.current) {
            resultSlideApi.start({ 
                backgroundColor: `${getNextColor()}55`,
                onRest: fadeToNextColor
            });
        }
    };

    useEffect(() => {
        loadingResultsRef.current = props.loadingResults;

        if(props.loadingResults) {
            fadeToNextColor();
        } else {
            if (lineData) {
                resultSlideApi.stop();
                resultSlideApi.start({
                    backgroundColor: `${lineData.color}4d`,
                    border: `3px solid ${lineData.color}80`,
                    config: { duration: 1000 }
                });
            } else {
                resultSlideApi.stop();
                resultSlideApi.start({
                    backgroundColor: `#00000055`,
                    border: '3px solid transparent',
                    config: { duration: 1000 }
                });
            }
        }
    }, [props.loadingResults]);

    return (
        <SlideCardContainer onClick={() => props.slideClicked(1)}>
            <ReferenceRatingLine
                showLine={!lineData ? false : lineData.visible}
                lineColor={!lineData ? '#00000000' : lineData.color}
            >
                <div className='container' style={{
                    top: !lineData ? 0 : `calc(${1 - (lineData.rating - 1) / 99} * (100% - 10px))`,
                }}>
                    <div className='large-part' />
                    <div className='small-part' />
                </div>
            </ReferenceRatingLine>
            <SlideCard style={resultSlideStyle}>
                {props.loadingResults ? <p style={{ fontWeight: 'bold' }}>Изчисляваме резултатите<br/>...</p> : props.children}
            </SlideCard>
        </SlideCardContainer>
    );
};

export default ResultSlide;