import { Answer } from '@kazvabg/voterapp-model';

import { SubmitAnswerFunction } from '../QuestionAndAnswer';
import StyledButton from '../../../components/StyledButton';

import Fade from '../../../components/Fade';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { AppContext } from '../../../App';
import { detectMobileOS } from '../../../util/util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCancel, faStreetView } from '@fortawesome/free-solid-svg-icons'
import React from 'react';

import { SpinnerCircularFixed } from 'spinners-react';

interface RequestLocationProps {
    questionId: number;
    question: string;
    answers: Answer[];
    submitAnswer: SubmitAnswerFunction;
}

const RequestLocation: React.FC<RequestLocationProps> = (props) => {

    const { topic, updatePreciseLocation } = useContext(AppContext);

    const [state, setState] = useState({ loading: false });

    const { i18n, t } = useTranslation();

    const submitAnswerText = (answerText: string, nextQuestionId: number | null | undefined) => {
        if (nextQuestionId)
            props.submitAnswer(answerText, nextQuestionId);
        else
            props.submitAnswer(answerText);
    };

    const buttonClicked = async (answer: Answer) => {

        if ("geolocation" in navigator) {

            setState({ ...state, loading: true });

            try {
                await updatePreciseLocation();
                setState({ ...state, loading: false });
                submitAnswerText('location request granted', answer.nextQuestionId);
            } catch (error: unknown) {
                setState({ ...state, loading: false });
                // Error callback - user denied permission or another error occurred
                if(error instanceof GeolocationPositionError) {
                    const geolocationError = error as GeolocationPositionError;
                    switch (geolocationError.code) {
                        case geolocationError.PERMISSION_DENIED:
                            submitAnswerText('location request denied', answer.nextQuestionId);
                            break;
                        case geolocationError.POSITION_UNAVAILABLE:
                        case geolocationError.TIMEOUT:
                        default:
                            submitAnswerText('location request error', answer.nextQuestionId);
                            break;
                    }
                } else {
                    submitAnswerText('unknown error', answer.nextQuestionId);
                }
            }

        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    };

    const getAnswerText = (answer: Answer, index: number) => {

        let answerText = answer.text;

        const currentLanguage = i18n.language;

        if (currentLanguage !== topic?.defaultLanguage && topic?.altLngs.includes(currentLanguage)) {
            answerText = topic?.translations[currentLanguage].qna[props.questionId ?? 0].answers[index + 1].text;
        }

        return answerText;
    };

    return (
        <Fade cascade>
            {props.answers.map((answer: Answer, i: number) =>
                <React.Fragment key={i}>
                    <StyledButton onClick={() => buttonClicked(answer)} disabled={state.loading}>
                        {state.loading ?
                            <>
                                <SpinnerCircularFixed
                                    speed={400}
                                    color={'#333'}
                                    secondaryColor={'#666'}
                                    thickness={100}
                                    size={24}
                                    style={{ verticalAlign: 'middle', margin: '-5px 2px 0 0' }}
                                /> Зареждане...
                            </> :
                            <>
                                <FontAwesomeIcon icon={faStreetView} /> {getAnswerText(answer, i)}
                            </>
                        }
                    </StyledButton>
                    <StyledButton onClick={() => submitAnswerText('location request skipped', answer.nextQuestionId)} disabled={state.loading}>
                        {t('button_skip')}
                    </StyledButton>
                </React.Fragment>
            )}
        </Fade>
    );
};

export default RequestLocation;