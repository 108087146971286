import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPeopleGroup } from "@fortawesome/free-solid-svg-icons/faPeopleGroup";
import { faMedal } from "@fortawesome/free-solid-svg-icons/faMedal";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons/faThumbsUp";
import { faThumbsDown } from "@fortawesome/free-solid-svg-icons/faThumbsDown";

import TypingTextV2 from "../../../../components/TypingTextV2";
import { useTranslation } from "react-i18next";
import { fixBold } from "../../../../util/util";
import { GroupResult } from "@kazvabg/voterapp-model";

interface TotalResultSlideProps {
    groupResult: GroupResult;
    voteValue: number;
    fadeInComplete: boolean;
    onAnimationComplete: () => void;
}

const TotalResultSlide: React.FC<TotalResultSlideProps> = props => {

    const { t } = useTranslation();

    if(!props.groupResult) {
        return null;
    }

    if (props.groupResult.voteCount === 1) {
        return (
            <>
                <span className='header-icon-row'>
                    <span className='icon'><FontAwesomeIcon icon={faPeopleGroup} /></span>
                    <span className='icon'><FontAwesomeIcon icon={faMedal} /></span>
                </span>
                <p>
                    <TypingTextV2 active={props.fadeInComplete} delay={10} onComplete={props.onAnimationComplete}>
                        {fixBold(t('results_majority_first_vote'))}
                    </TypingTextV2>
                </p>
            </>
        );
    }

    const averageVote: number = props.groupResult.rating ?? 50;
    const userVote = props.voteValue;

    const votesDiff = Math.abs(userVote - averageVote);

    let agreementMessage;
    let agreementIcon;

    if(votesDiff <= 15) {
        agreementIcon = faThumbsUp;
        agreementMessage = fixBold(t('results_majority_strong_agreement'));
    } else if (votesDiff <= 30) {
        agreementIcon = faThumbsUp;
        agreementMessage = fixBold(t('results_majority_weak_agreement'));
    } else if(votesDiff <= 50) {
        agreementIcon = faThumbsDown;
        agreementMessage = fixBold(t('results_majority_weak_disagreement'));
    } else {
        agreementIcon = faThumbsDown;
        agreementMessage = fixBold(t('results_majority_strong_disagreement'));
    }

    return (!props.groupResult? null :
        <>
            <span className='header-icon-row'>
                <span className='icon'><FontAwesomeIcon icon={faPeopleGroup} /></span>
                <span className='icon'><FontAwesomeIcon icon={agreementIcon} /></span>
            </span>
            <TypingTextV2 active={props.fadeInComplete} delay={10} onComplete={props.onAnimationComplete}>
                <p>{agreementMessage}</p>
            </TypingTextV2>
        </>
    );
};

export default TotalResultSlide;