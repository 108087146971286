import React, { useContext, useState } from 'react';

import { Answer } from '@kazvabg/voterapp-model';
import { SubmitAnswerFunction } from '../QuestionAndAnswer';
import StyledButton from '../../../components/StyledButton';

import styled from 'styled-components';
import Fade from '../../../components/Fade';
import OptionsButton from '../../../components/OptionsButton';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../App';

const CheckboxRow = styled.div`
    display: block;
    color: white;
    vertical-align: top;

    &:hover {
        cursor: pointer;
        background: #00000088;
    }

    input[type='checkbox'] {
        display: none;
    }

    input[type='checkbox'] + label {
        cursor: pointer;
        vertical-align: top;
        display: block;
        font-size: 16px;
        padding: 10px 20px;
    }

    input[type='checkbox'] + label::before {
        vertical-align: top;
        margin-right: 10px;
        content: '';
        width: 30px;
        height: 30px;
        display: inline-block;
        background: 
            radial-gradient(101.06% 100% at 50% 100%,rgba(94,94,94,0.14) 0%,rgba(94,94,94,0) 73.85%), 
            radial-gradient(100% 100% at 50% 100%,rgba(255,255,255,0.07) 0%,rgba(255,255,255,0) 55.59%), 
            #FFFFFF0F;
        background-blend-mode: color-dodge, color-dodge, lighten;
        box-shadow: 0 -2px 0px #FFFFFF83 inset, 0 2px 0px #B3B3B399 inset, -2px 0 0px #FFFFFF52 inset, 2px 0 0px #FFFFFF52 inset;
        border-radius: 3px;
    }
    
    input[type='checkbox']:checked + label::before {
        background-color: white;
        box-shadow: none;
        font-size: 28px;
        padding: 1px 4px;
    }

    .checkbox-text {
        display: inline-block;
        width: calc(100% - 40px);
    }
`;

interface AnswerMultiSelectProps {
    questionId: number;
    question: string;
    answers: Answer[];
    submitAnswer: SubmitAnswerFunction;
}

export default (props: AnswerMultiSelectProps) => {
    const [selectedList, setSelectedList] = useState<boolean[]>(props.answers.map(() => false));

    const { topic } = useContext(AppContext);

    const { t, i18n } = useTranslation();

    const selectAnswer = (i: number) => {
        selectedList[i] = !selectedList[i];
        setSelectedList([...selectedList]);
    };

    const buttonClicked = () => {
        let answersString = '';

        for (let i = 0; i < selectedList.length; i++) {
            if (selectedList[i]) {
                answersString += `${props.answers[i].text}/%/`
            }
        }

        props.submitAnswer(answersString);
    };

    const getAnswerText = (answer: Answer, index: number) => {

        let answerText = answer.text;

        const currentLanguage = i18n.language;

        if(currentLanguage !== topic?.defaultLanguage && topic?.altLngs.includes(currentLanguage)) {
            answerText = topic?.translations[currentLanguage].qna[props.questionId ?? 0].answers[index + 1].text;
        } 

        return answerText;
    }

    return (
        <Fade cascade>
            <div style={{ background: '#00000055', marginBottom: '20px', padding: '20px 0', borderRadius: '20px' }}>
                <Fade cascade>
                    {props.answers.map((answer, i) =>
                        <CheckboxRow key={answer.answerId}>
                            <input 
                                type="checkbox" 
                                id={answer.answerId.toString()} 
                                name={answer.answerId.toString()} 
                                value={answer.text}
                                onClick={() => selectAnswer(i)}
                            />
                            <label htmlFor={answer.answerId.toString()}>
                                <div className='checkbox-text'>{getAnswerText(answer, i)}</div>
                            </label>
                        </CheckboxRow>
                    )}
                </Fade>
            </div>
            <Fade>
                <StyledButton disabled={!selectedList.some(Boolean)} onClick={buttonClicked}>
                    {t('button_confirm')}
                </StyledButton>
            </Fade>
        </Fade>
    );
};