import { PropsWithChildren } from "react";
import { animated, useSpring } from "react-spring";

interface FadeProps extends PropsWithChildren {
    cascade?: boolean;
    onComplete?: () => void;
}

const Fade: React.FC<FadeProps> = (props) => {

    const onRestFunction = () => {
        if(props.onComplete)
            props.onComplete();
    };

    if (props.cascade && props.children && Array.isArray(props.children))
        return (
            <>
                {props.children.map((child, i) =>
                    <animated.div key={i} style={useSpring({
                        from: { opacity: 0 },
                        to: { opacity: 1 },
                        delay: i * 100,
                        config: { duration: 600 },
                        onRest: onRestFunction
                    })}>
                        {child}
                    </animated.div>
                )}
            </>
        );

    return (
        <animated.div style={useSpring({
            from: { opacity: 0 },
            to: { opacity: 1 },
            config: { duration: 600 },
            onRest: onRestFunction
        })}>
            {props.children}
        </animated.div>
    );
};

export default Fade;