import React, { useContext, useState } from 'react';
import { AppContext, MenuScreen } from '../App';
import Toggle from 'react-toggle';
import "react-toggle/style.css";

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCookieBite } from '@fortawesome/free-solid-svg-icons/faCookieBite';
import StyledButton from '../components/StyledButton';
import StyledContentPanel from '../components/StyledContentPanel';
import { useTranslation } from 'react-i18next';

const LinkButton = styled.button`
    border: none;
    display: inline;
    background: none;
    color: #46d4ff;
    cursor: pointer;
    text-decoration: underline;
`;

const ConsentToggle = styled.div`
    margin: 20px 0 0 0;
    width: 100%;
    display: inline-block;
    color: white;

    .react-toggle {
        outline: none;

        .react-toggle-track {
            width: 100px;
            height: 48px;
            background: #00000026;

            box-shadow: 
                0px 3px 3px 0px #00000026 inset,
                0px 3px 3px 0px #8080802E inset,
                0px -0.5px 1px 0px #FFFFFF33 inset,
                0px -0.5px 1px 0px #5E5E5E4D inset;
        }

        .react-toggle-thumb {
            width: 40px;
            height: 40px;
            top: 4px;
            box-shadow: none;
            border: none;
            background-color: #eee;
            box-shadow: 0px 1px 1px 1px #00000038;
            left: 4px;
        }

        .react-toggle-track-check {
            width: 28px;
            height: 22px;
            left: 10px;
            svg {
                width: 28px;
                height: 22px;
            }
        }

        .react-toggle-track-x {
            width: 28px;
            height: 22px;
            right: 10px;
            svg {
                width: 28px;
                height: 22px;
            }
        }
    }

    .react-toggle--checked {
        
        .react-toggle-track {
            background: #0EFF1D54;
        }
        
        .react-toggle-thumb {
            left: 54px;
        }
    }

    label {
        display: inline-block;
        width: calc(100% - 110px);
        padding-left: 10px;
        vertical-align: top;
        font-size: 22px;
        padding-top: 10px;
    }
`;

const ConsentScreenComponent: React.FC = () => {
    const { giveCookieConsent, menuScreen, setMenuScreen } = useContext(AppContext);

    const [consentToggled, setConsentToggled] = useState(false);

    const { t } = useTranslation();

    return (
        <div style={{ padding: '20px' }}>
            <StyledContentPanel>
                <h1>
                    <span style={{ marginRight: '10px' }}><FontAwesomeIcon icon={faCookieBite} /></span>
                    {t('cookie_consent_title')}
                </h1>
                <p>
                    {t('cookie_consent_text')}
                </p>
                <p>
                    {t('cookie_consent_according')} <LinkButton onClick={() => setMenuScreen(MenuScreen.PrivacyNotice)}>{t('menu_main_privacy_notice')}</LinkButton>
                </p>
                <ConsentToggle>
                    <Toggle
                        checked={consentToggled}
                        onChange={() => setConsentToggled(!consentToggled)}
                    />
                    <label>{t('cookie_consent_allow')}</label>
                </ConsentToggle>
            </StyledContentPanel>
            <StyledButton onClick={giveCookieConsent} disabled={!consentToggled} style={{ marginTop: '20px' }}>
                {t('cookie_consent_allow')}
            </StyledButton>
        </div>
    );
}

export default ConsentScreenComponent;