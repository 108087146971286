import styled from "styled-components";

const StyledTextArea = styled.textarea`
    padding: 20px 20px;
    display: block;
    width: 100%;
    margin-bottom: 20px;
    box-sizing: border-box;
    border: none;
    border-radius: 20px;
    outline: none;
    background: none;
    font-size: 16px; //22px;
    color: white;
    box-shadow: 
        1px 1.5px 4px 0px #0000001A inset,
        1px 1.5px 4px 0px #00000014 inset,
        0px -0.5px 1px 0px #FFFFFF40 inset,
        0px -0.5px 1px 0px #FFFFFF4D inset;
    //background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), rgba(208, 208, 208, 0.50);
    //background-blend-mode: color-burn; 
    background: #0006;
    resize: vertical;
    height: auto;
    min-height: 100px;
`;

export default StyledTextArea;