import { useContext } from "react";
import { AppContext, MenuScreen } from "../../App";
import StyledButton from "../../components/StyledButton";
import StyledContentPanel from "../../components/StyledContentPanel";
import axios from "axios";
import toast from "react-hot-toast";
import Fade from "../../components/Fade";
import { useTranslation } from "react-i18next";

const AreYouSureScreen: React.FC = () => {

    const { apiUrl, setMenuScreen, setProfile } = useContext(AppContext);

    const { t } = useTranslation();

    const deleteProfile = async () => {
        await axios.delete(`${apiUrl}/user`);
        setProfile(null);
        setMenuScreen(MenuScreen.Profile);
        toast('Профилът е изтрит успешно!');
    };

    return (
        <div style={{ padding: '20px' }}>
            <Fade cascade>
                <StyledContentPanel>
                    <h1>{t('menu_profile_ays_title')}</h1>
                    <p>{t('menu_profile_ays_subtitle')}</p>
                </StyledContentPanel>
                <StyledButton style={{ backgroundColor: '#ff0000', color: 'white', marginTop: '20px', opacity: 0.8 }} onClick={deleteProfile}>
                    {t('menu_profile_ays_button_yes')}
                </StyledButton>
                <StyledButton onClick={() => setMenuScreen(MenuScreen.Profile)}>
                    {t('menu_profile_ays_button_no')}
                </StyledButton>
            </Fade>
        </div>
    );
}

export default AreYouSureScreen;