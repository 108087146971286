export const generateTopicSlug = (topicTn: string) => {
    return topicTn.replace(/\s+/g, '').toLowerCase()
};

export const hexToRgb = (hex: string) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : {
        r: 0, g: 0, b: 0
    };
};

export type Color = { r: number, g: number, b: number };

export const interpolateColor = (color1: Color, color2: Color, factor: number) => {
    if (factor > 1) factor = 1;
    else if (factor < 0) factor = 0;

    const result = {
        r: Math.round(color1.r + factor * (color2.r - color1.r)),
        g: Math.round(color1.g + factor * (color2.g - color1.g)),
        b: Math.round(color1.b + factor * (color2.b - color1.b))
    };

    return `#${((1 << 24) + (result.r << 16) + (result.g << 8) + result.b).toString(16).slice(1)}`;
};

export const ratingColorGradient = (colors: string[], sliderValue: number) => {
    const scaledSliderValue = (sliderValue ?? 0) / 20 - 0.001;
    const color1 = colors[Math.floor(scaledSliderValue)];
    const color2 = colors[Math.ceil(scaledSliderValue)];
    const factor = scaledSliderValue % 1;

    return interpolateColor(hexToRgb(color1), hexToRgb(color2), factor);
};

export const capitalizeFirst = (str: string) => {
    return str[0].toUpperCase() + str.substring(1);
};

export const fixBold = (message: string) => {
    return (
        message.split(/<b>|<\/b>/).map((part, index) =>
            index % 2 === 1 ? <b key={index}> {part} </b> : part
        )
    );
};

export const detectMobileOS = () : 'android' | 'ios' | 'unknown' => {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

    if (/android/i.test(userAgent)) {
        return "android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
        return "ios";
    }

    return "unknown";
};