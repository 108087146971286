import styled from "styled-components";

const ButtonStyle = styled.button`
    border-radius: 20px;
    cursor: pointer;
    border: none;
    display: block;
    padding: 20px;
    color: white;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 20px;
    width: 100%;
    opacity: 0.75;
    
    background-blend-mode: color-dodge, color-dodge, color-dodge, lighten;
    background: 
        radial-gradient(101.06% 100% at 50% 100%, rgba(94, 94, 94, 0.14) 0%, rgba(94, 94, 94, 0) 73.85%),
        radial-gradient(100% 100% at 50% 100%, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0) 55.59%);
    background-color: #FFFFFFE0;
    color: #333;

    &:disabled {
        background-color: #FFFFFF54;
        cursor: default;
    }
`;

export default ButtonStyle;