import React, { useMemo, useState } from "react";
import { useEffect, useRef } from "react";
import styled from "styled-components";

const TypingTextStyle = styled.div`
    //background: red;
    transition: height 1s ease;

    span.visible-part {

    }

    span.transparent-part {
        opacity: 0;
    }
`;

interface TypingTextProps {
    delay?: number;
    active?: boolean;
    reverse?: boolean;
    reset?: boolean;
    onComplete?: () => void;
    children: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function deepEqual(a: any, b: any) {
    // Check if the two values are the same value or are both exactly null
    if (a === b) return true;

    // If they are not objects (including null and undefined), they are not equal
    if (a == null || typeof a !== "object" || b == null || typeof b !== "object") {
        return false;
    }

    const disregardedKeys: string[] = [];

    const keysA = Object.keys(a).filter(key => key[0] !== '_' && !disregardedKeys.includes(key));
    const keysB = Object.keys(b).filter(key => key[0] !== '_' && !disregardedKeys.includes(key));

    // If their property lengths are different, they are different
    if (keysA.length !== keysB.length) return false;

    // Check if they have the same set of keys
    for (const key of keysA) {
        if (!keysB.includes(key)) return false;
    }

    // Recursively check each property
    for (const key of keysA) {
        if (!deepEqual(a[key], b[key])) return false;
    }

    // If all tests pass, then they are deeply equal
    return true;
}

const TypingText: React.FC<TypingTextProps> = (props) => {

    const timeoutRef = useRef<NodeJS.Timeout | number | null>(null);
    const stringRefs = useRef<Array<string>>([]);
    const itemRefs = useRef<Array<HTMLSpanElement | null>>([]);
    const currentSpanRef = useRef<number>(0);
    const reverseRef = useRef<boolean>(false);
    const finishedTyping = useRef<boolean>(false);

    const typingLoop = () => {
        timeoutRef.current = setTimeout(() => {

            const visiblePartRef = itemRefs.current[currentSpanRef.current];
            const transparentPartRef = itemRefs.current[currentSpanRef.current + 1];

            const visibleStringRef = stringRefs.current[currentSpanRef.current];
            const transparentStringRef = stringRefs.current[currentSpanRef.current + 1];

            if (!visiblePartRef || !transparentPartRef)
                return;

            if (visiblePartRef.textContent === null || transparentPartRef.textContent === null)
                return;

            if (reverseRef.current
                ? visibleStringRef.length > 0
                : transparentStringRef.length > 0
            ) {

                const nextLetter = reverseRef.current
                    ? visibleStringRef[visibleStringRef.length - 1]
                    : transparentStringRef[0];

                if (reverseRef.current) {

                    stringRefs.current[currentSpanRef.current] = visibleStringRef.slice(0, visibleStringRef.length - 1);
                    stringRefs.current[currentSpanRef.current + 1] = nextLetter + transparentStringRef;

                    visiblePartRef.textContent = stringRefs.current[currentSpanRef.current];
                    transparentPartRef.textContent = stringRefs.current[currentSpanRef.current + 1];

                } else {

                    stringRefs.current[currentSpanRef.current] = visibleStringRef + nextLetter;
                    stringRefs.current[currentSpanRef.current + 1] = transparentStringRef.slice(1);

                    visiblePartRef.textContent = stringRefs.current[currentSpanRef.current];
                    transparentPartRef.textContent = stringRefs.current[currentSpanRef.current + 1];

                }

                typingLoop();

            } else if (reverseRef.current
                ? currentSpanRef.current - 2 > 0
                : currentSpanRef.current + 2 < itemRefs.current.length
            ) {

                if (reverseRef.current) {
                    currentSpanRef.current -= 2;
                } else {
                    currentSpanRef.current += 2;
                }

                typingLoop();

            } else {
                timeoutRef.current = null;
                finishedTyping.current = true;
                if (props.onComplete && typeof props.onComplete === 'function')
                    props.onComplete();
            }

        }, props.delay ?? 20);
    };

    const counterRef = useRef(0);

    const modifyText = (child: React.ReactNode): string | React.ReactNode => {
        if (typeof child === 'string' || typeof child === 'number') {
            const childString: string = typeof child === 'number' ? child.toString() : child;

            if (!childString.trim())
                return;

            const refFunction = (el: HTMLSpanElement) => {
                if (el)
                    itemRefs.current[counterRef.current++] = el;
            };

            if (finishedTyping.current && !reverseRef.current) {

                stringRefs.current.push(childString);
                stringRefs.current.push("");

                return [
                    <span ref={refFunction} className='visible-part'>{childString}</span>,
                    <span ref={refFunction} className='transparent-part'></span>
                ];
            } else {

                stringRefs.current.push("");
                stringRefs.current.push(childString);

                return [
                    <span ref={refFunction} className='visible-part'></span>,
                    <span ref={refFunction} className='transparent-part'>{childString}</span>
                ];
            }
        } else if (React.isValidElement(child)) {
            return React.cloneElement(child, child.props, React.Children.map(child.props.children, modifyText));
        } else {
            return child;
        }
    };

    const [children, setChildren] = useState(props.children);

    const updatingChildrenRef = useRef(false);

    useEffect(() => {
        if (props.reset) {
            currentSpanRef.current = 0;
            finishedTyping.current = false;
        }
    }, [props.reset]);

    useEffect(() => {

        if (!deepEqual(children, props.children)) {
            itemRefs.current = [];
            stringRefs.current = [];
            counterRef.current = 0;
            if (timeoutRef.current)
                clearTimeout(timeoutRef.current);
            updatingChildrenRef.current = true;
            setChildren(props.children);
        }

    }, [props.children]);

    useEffect(() => {
        updatingChildrenRef.current = false;

        reverseRef.current = props.reverse ?? false;

        for (let i = 0; i < stringRefs.current.length; i += 2) {

            const visibleSpan = itemRefs.current[i];
            const transparentSpan = itemRefs.current[i + 1];

            if (!visibleSpan || !transparentSpan)
                continue;

            if (visibleSpan.textContent === null || visibleSpan.textContent === null)
                continue;

            visibleSpan.textContent = stringRefs.current[i] ?? '';
            transparentSpan.textContent = stringRefs.current[i + 1] ?? '';

        }

        if (props.active === undefined || props.active === true) {

            if (!timeoutRef.current) {
                finishedTyping.current = false;
                typingLoop();
            }
        }
    }, [children]);

    useEffect(() => {
        if (!updatingChildrenRef.current) {
            reverseRef.current = props.reverse ?? false;

            if (props.active === undefined || props.active === true) {

                if (!timeoutRef.current) {
                    finishedTyping.current = false;
                    typingLoop();
                }
            }
        }
    }, [props.active, props.reverse]);

    return (useMemo(() =>
        <TypingTextStyle>
            {React.Children.map(props.children, modifyText)}
        </TypingTextStyle>
        , [children]));
};

export default TypingText;