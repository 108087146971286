import { useContext } from "react";
import { AppContext } from "../../../../App";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMars } from "@fortawesome/free-solid-svg-icons/faMars";
import { faMedal } from "@fortawesome/free-solid-svg-icons/faMedal";
import { faVenus } from "@fortawesome/free-solid-svg-icons/faVenus";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons/faThumbsUp";
import { faThumbsDown } from "@fortawesome/free-solid-svg-icons/faThumbsDown";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons/faCircleQuestion";
import { faVenusMars } from "@fortawesome/free-solid-svg-icons/faVenusMars";
import StyledDropDown from "../../../../components/StyledDropdown";
import { useTranslation } from "react-i18next";
import { capitalizeFirst, fixBold } from "../../../../util/util";
import { GroupResult } from "@kazvabg/voterapp-model";

interface GenderResultSlideProps {
    groupResult?: GroupResult;
    voteValue: number;
    submitGender: React.ChangeEventHandler;
}

const GenderResultSlide: React.FC<GenderResultSlideProps> = props => {
    const { profile } = useContext(AppContext);

    const { t } = useTranslation();

    if (!profile.gender || !props.groupResult)
        return (
            <>
                <span className='header-icon-row'>
                    <span className='icon'><FontAwesomeIcon icon={faVenusMars} /></span>
                    <span className='icon'><FontAwesomeIcon icon={faCircleQuestion} /></span>
                </span>
                <p style={{ fontSize: '16px', marginBottom: '10px' }}>{t('results_gender_input_let_me_show_you')}</p>
                <StyledDropDown style={{ fontSize: '16px', padding: '17px 20px', marginLeft: '-6px', width: 'calc(100% + 12px)' }} onChange={props.submitGender} value={capitalizeFirst(t('input_placeholder_gender'))}>
                    <option disabled>{capitalizeFirst(t('input_placeholder_gender'))}</option>
                    <option value="male">{capitalizeFirst(t('general_male'))}</option>
                    <option value="female">{capitalizeFirst(t('general_female'))}</option>
                </StyledDropDown>
                <p style={{ fontSize: '11px', marginTop: '10px' }}>{t('results_gender_input_disclaimer')}</p>
            </>
        );

    if (profile.gender === 'other')
        return null;

    if (props.groupResult.voteCount === 1) {
        const genderIcon = profile.gender === 'male' ? faMars : faVenus;
        return (
            <>
                <span className='header-icon-row'>
                    <span className='icon'><FontAwesomeIcon icon={genderIcon} /></span>
                    <span className='icon'><FontAwesomeIcon icon={faMedal} /></span>
                </span>
                <p>
                    {profile.gender === 'male'
                        ? fixBold(t('results_gender_first_vote_male'))
                        : fixBold(t('results_gender_first_vote_female'))
                    }
                </p>
            </>
        );
    }

    if (props.groupResult.voteHeight === null)
        return null;

    const genderIcon = profile.gender === 'male' ? faMars : faVenus;
    const genderStr = profile.gender === 'male' ? t('general_male_plural') : t('general_female_plural');

    const userVote = props.voteValue;
    const votesDiff = Math.abs(userVote - (props.groupResult.rating ?? 50));

    let agreementMessage;
    let agreementIcon;

    if(votesDiff <= 15) {
        agreementIcon = faThumbsUp;
        agreementMessage = fixBold(t('results_gender_strong_agreement', { genderStr }));
    } else if (votesDiff <= 30) {
        agreementIcon = faThumbsUp;
        agreementMessage = fixBold(t('results_gender_weak_agreement', { genderStr }));
    } else if(votesDiff <= 50) {
        agreementIcon = faThumbsDown;
        agreementMessage = fixBold(t('results_gender_weak_disagreement', { genderStr }));
    } else {
        agreementIcon = faThumbsDown;
        agreementMessage = fixBold(t('results_gender_strong_disagreement', { genderStr }));
    }

    return (
        <>
            <span className='header-icon-row'>
                <span className='icon'><FontAwesomeIcon icon={genderIcon} /></span>
                <span className='icon'><FontAwesomeIcon icon={agreementIcon} /></span>
            </span>
            <p>{agreementMessage}</p>
        </>
    );
};

export default GenderResultSlide;