import styled from 'styled-components';

const GlassPanel = styled.div`
    max-width: 550px;
    margin: 20px auto;
    border-radius: 20px;
    box-shadow: 0 -1px 1px #FFFFFF66, 0 1px 1px #FFFFFF1A, -1px 0 1px #FFFFFF00, 1px 0 1px #FFFFFF00;
    box-sizing: border-box;
    height: calc(100% - 40px);
    overflow-y: scroll;
    background-color: #8080804D;
    backdrop-filter: blur(0px) saturate(1) brightness(1);
    transition: backdrop-filter 1s ease, -webkit-backdrop-filter 1s ease;

    @media only screen and (max-width: 550px) {
        width; 100%;
        height: 100%;
        max-height: 100%;
        margin: 0;
        border-radius: 0;
    }
`;

export default GlassPanel;