import { Answer } from '@kazvabg/voterapp-model';

import { SubmitAnswerFunction } from '../QuestionAndAnswer';
import StyledButton from '../../../components/StyledButton';

import OptionButton from '../../../components/OptionsButton';

import Fade from '../../../components/Fade';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { AppContext } from '../../../App';
import { detectMobileOS } from '../../../util/util';

interface AnswerSingleSelectProps {
    questionId: number;
    question: string;
    answers: Answer[];
    submitAnswer: SubmitAnswerFunction;
}

export default (props: AnswerSingleSelectProps) => {

    const { topic } = useContext(AppContext);

    const { i18n } = useTranslation();

    const buttonClicked = (answer: Answer) => {
        if (answer.link) {
            let answerLink = answer.link;
            const mobileOs = detectMobileOS();
        
            const matches = answerLink.match(/{(.*?)}/g) ?? [];
            
            for(const match of matches) {
                const strippedStr = match.slice(1, match.length - 1);
                
                let strToReplaceWith = '';
        
                if(mobileOs === 'ios' && strippedStr.startsWith('ios:')) {
                    strToReplaceWith = strippedStr.replace('ios:', '');
                } else if(mobileOs === 'android' && strippedStr.startsWith('android:')) {
                    strToReplaceWith = strippedStr.replace('android:', '');
                } else if(mobileOs === 'unknown' && strippedStr.startsWith('default:')) {
                    strToReplaceWith = strippedStr.replace('default:', '');
                }
        
                answerLink = answerLink.replace(match, strToReplaceWith);
            }

            window.open(answerLink, '_blank');
        }

        if (answer.nextQuestionId)
            props.submitAnswer(answer.text, answer.nextQuestionId);
        else
            props.submitAnswer(answer.text);
    };

    const getAnswerText = (answer: Answer, index: number) => {

        let answerText = answer.text;

        const currentLanguage = i18n.language;

        if(currentLanguage !== topic?.defaultLanguage && topic?.altLngs.includes(currentLanguage)) {
            answerText = topic?.translations[currentLanguage].qna[props.questionId ?? 0].answers[index + 1].text;
        } 

        return answerText;
    }

    return (
        <Fade cascade>
            {props.answers.map((answer: Answer, i: number) =>
                <StyledButton key={i} onClick={() => buttonClicked(answer)}>
                    {answer.link ? '🌐' : null} {getAnswerText(answer, i)}
                </StyledButton>
            )}
        </Fade>
    );
};