import { useEffect, useRef } from "react";

const morphTarget0 = [
    [null, null, null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null, null, null],
    [null, null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null, null],
    [null, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, null],
    [0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0],
    [0, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0],
    [0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0],
    [null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null],
    [null, null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null, null],
    [null, null, null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null, null, null],
];

const morphTarget1 = [
    [null, null, null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null, null, null],
    [null, null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null, null],
    [null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null],
    [0, 0, 0, 1, 1, 1, 1, 0, 0, 1, 1, 1, 1, 0, 0, 0],
    [0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0],
    [0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null],
    [null, null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null, null],
    [null, null, null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null, null, null],
];

const morphTarget2 = [
    [null, null, null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null, null, null],
    [null, null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null, null],
    [null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null],
    [0, 0, 0, 1, 1, 1, 1, 0, 0, 1, 1, 1, 1, 0, 0, 0],
    [0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null],
    [null, null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null, null],
    [null, null, null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null, null, null],
];

const morphTarget3 = [
    [null, null, null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null, null, null],
    [null, null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null, null],
    [null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null],
    [0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0],
    [0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0],
    [0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null],
    [null, null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null, null],
    [null, null, null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null, null, null],
];

const morphTarget4 = [
    [null, null, null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null, null, null],
    [null, null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null, null],
    [null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null],
    [0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0],
    [0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0],
    [0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null],
    [null, null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null, null],
    [null, null, null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null, null, null],
];

const morphTarget5 = [
    [null, null, null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null, null, null],
    [null, null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null, null],
    [null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null],
    [0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0],
    [0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0],
    [0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null],
    [null, null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null, null],
    [null, null, null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null, null, null],
];

const morphTarget6 = [
    [null, null, null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null, null, null],
    [null, null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null, null],
    [null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null],
    [0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0],
    [0, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0],
    [0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null],
    [null, null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null, null],
    [null, null, null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null, null, null],
];

const morphTargets = [morphTarget0, morphTarget1, morphTarget2, morphTarget3, morphTarget4, morphTarget5, morphTarget6];
const morphValues = [1, 20, 40, 50, 60, 80, 100];

interface SmileyFaceDots {
    //sliderValue: number;
    updateSmileyRef: (updateSmiley: (sliderValue: number) => void) => void;
}

const SmileyFaceDots: React.FC<SmileyFaceDots> = props => {

    const canvasRef = useRef<HTMLCanvasElement>(null);

    props.updateSmileyRef(sliderValue => {
        renderCanvas(sliderValue);
    });

    const renderCanvas = (sliderValue: number) => {
        const canvas = canvasRef.current;
        const context = canvas?.getContext('2d');
        if(!context || canvas === null) return;

        let morphTarget1Id = 0;
        let morphTarget2Id = 0;

        if (sliderValue < morphValues[0]) {

            morphTarget1Id = 0;
            morphTarget2Id = 0;

        } else if (sliderValue > morphValues[morphValues.length - 1]) {

            morphTarget1Id = morphValues.length - 1;
            morphTarget2Id = morphValues.length - 1;

        } else {
            for (let i = 0; i < morphValues.length - 1; i++) {
                const morphValue1 = morphValues[i];
                const morphValue2 = morphValues[i + 1];

                if (sliderValue >= morphValue1 && sliderValue <= morphValue2) {
                    morphTarget1Id = i;
                    morphTarget2Id = i + 1;
                    break;
                }
            }
        }

        const morphValue1 = morphValues[morphTarget1Id];
        const morphValue2 = morphValues[morphTarget2Id];

        const morphTarget1 = morphTargets[morphTarget1Id];
        const morphTarget2 = morphTargets[morphTarget2Id];

        const factor = (sliderValue - morphValue1) / (morphValue2 - morphValue1);

        const dotSize = canvas.width / 16;

        context.clearRect(0, 0, canvas.width, canvas.height);

        context.fillStyle = '#fff';

        const padding = 1;

        for (let rowId = 0; rowId < morphTarget1.length; rowId++) {
            const row = morphTarget1[rowId];

            for (let colId = 0; colId < row.length; colId++) {
                context.beginPath();

                const maxRadius = dotSize / 2;
                const x = dotSize * colId + maxRadius;
                const y = dotSize * rowId + maxRadius;

                const dot1 = morphTarget1[rowId][colId];
                const dot2 = morphTarget2[rowId][colId];

                if(dot1 === null || dot2 === null)
                    continue;

                const r = (1 * (dot1 * (1 - factor) + dot2 * factor)) * (maxRadius - padding);

                context.arc(x, y, r, 0, 2 * Math.PI);
                context.fillStyle = '#fff8';
                context.fill();
            }
        }
    };

    /*useEffect(() => {
        if(canvasRef.current && canvasRef.current.width > 0 && canvasRef.current.height > 0)
            renderCanvas(props.sliderValue);
    }, [props.sliderValue]);*/

    const resizeHandler = () => {
        const newSize = canvasRef.current?.parentElement?.clientWidth;

        if(newSize) {
            const dpr = window.devicePixelRatio || 1;
            const ctx = canvasRef.current.getContext('2d');
            if(ctx)
                ctx.scale(dpr, dpr);

            canvasRef.current.width = newSize * dpr;
            canvasRef.current.height = newSize * dpr;


        }
        
        //renderCanvas(props.sliderValue);
    };

    useEffect(() => {
        window.addEventListener('resize', resizeHandler);

        resizeHandler();

        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, []);

    return (
        <canvas ref={canvasRef} width={0} height={0} style={{ width: '100%', height: 'auto' }}/>
    );
};

export default SmileyFaceDots;