// Sets a cookie
export const setCookie = (name: string, value: string, days?: number): void => {
    let expires = "";
    if (days !== undefined) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = `${name}=${value}${expires}; path=/`;
};

// Gets a cookie
export const getCookie = (name: string): string | null => {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');

    for (let i = 0; i < ca.length; i++) {
        const c = ca[i].trim();
        if (c.startsWith(nameEQ)) {
            return c.substring(nameEQ.length);
        }
    }

    return null;
};

// Erases a cookie
export const eraseCookie = (name: string): void => {
    document.cookie = `${name}=; Max-Age=-99999999;`;
};