import React, { useContext, useEffect, useState } from 'react';

import axios from 'axios';

import { AppContext } from '../../App';
import DropdownAnswer from './answers/DropdownAnswer';
import OpenStructure from './answers/OpenStructure';
import SingleSelect from './answers/SingleSelect';
import MultiSelect from './answers/MultiSelect';

import { Question } from '@kazvabg/voterapp-model';
import { animated, useSpring } from 'react-spring';
import StyledContentPanel from '../../components/StyledContentPanel';
import { useTranslation } from 'react-i18next';
import TypingTextV2 from '../../components/TypingTextV2';
import DisplayMedia from './DisplayMedia';
import RequestLocation from './answers/RequestLocation';

export type VoteEndFunction = (voteEnd: boolean) => void;

interface QuestionAndAnswerProps {
    voteValue: number | null;
    voteId: number | null;
    setVoteEnd: VoteEndFunction;
    firstQuestionAnswered: () => void;
}

enum QnAAnimationState {
    ContentPanelFadeIn = 'content-panel-fade-in',
    TypeTitleText = 'type-title-text',
    MediaFadeIn = 'media-fade-in',
    MediaPlay = 'media-play',
    AnswersFadeIn = 'answers-fade-in',
    QuestionFadeOut = 'question-fade-out'
}

interface QuestionAndAnswerState {
    qnaAnimationState: QnAAnimationState;
    questionIndex: number | undefined;
    questionTime: number;
}

export type SubmitAnswerFunction = (answer: string, jump?: number | null) => void;

const QuestionAndAnswer: React.FC<QuestionAndAnswerProps> = (props) => {

    const { apiUrl, topic, geolocationConsent, preciseCoordinates } = useContext(AppContext);

    const { i18n } = useTranslation();

    const [qnaContainerStyle, qnaContainerApi] = useSpring(() => ({
        opacity: 0,
    }));

    const [questionMediaStyle, questionMediaApi] = useSpring(() => ({
        opacity: 0,
    }));

    const [dropdownAnswerStyle, dropdownAnswerApi] = useSpring(() => ({
        opacity: 0,
    }));

    const getQuestionIndex = (questionId: number) => {
        let questionIndex = 0;

        if(!topic)
            return -1;

        while(questionIndex < topic.qa.length) {
            const question = topic.qa[questionIndex];
            if(question.questionId === questionId)
                return questionIndex;
            questionIndex ++;
        }

        return -1;
    };

    const resolveNextQuestion = (questionIndex: number | null = null, jumpQuestionId: number | null = null): number | null => {
        //console.log('========================================================================');
        //console.log('Getting next question for question index', questionIndex,'and jump ID', jumpQuestionId);
        
        if(!topic) {
            //console.log('Topic is undefined; Ending QnA');
            props.setVoteEnd(true);
            return null;
        }

        if (jumpQuestionId !== null) {
            //console.log('Jump detected');
            const nextQuestionIndex = getQuestionIndex(jumpQuestionId);
            if(nextQuestionIndex >= 0) {
                //console.log('Jump destination exists; Jumping to', nextQuestionIndex);
                return nextQuestionIndex;
            } else {
                //console.log('Jumping to nonexistent question; Ending QnA');
                props.setVoteEnd(true);
                return null;
            }
        }

        const getNextQuestion = (questionIndex: number | null): number | null => {

            if(questionIndex === topic.qa.length - 1) {
                //console.log('This is last question; Ending QnA');
                props.setVoteEnd(true);
                return null;
            }

            const nextQuestionIndex = questionIndex === null ? 0 : questionIndex + 1;
            const nextQuestion = topic.qa[nextQuestionIndex];
            //console.log('Maybe next question is', nextQuestionIndex);

            if(!nextQuestion) {
                //console.log('Next question index not found; Ending QnA');
                props.setVoteEnd(true);
                return null;
            }

            if(nextQuestion.answerSctructure === 'location_request') {
                if(geolocationConsent !== 'prompt') {
                    return getNextQuestion(nextQuestionIndex);
                }
            }

            if(nextQuestion.isJumpOnly) {
                //console.log('Skipping jumpOnly question', nextQuestionIndex);
                return getNextQuestion(nextQuestionIndex);
            }

            if(nextQuestion.showForRange.length === 2 && props.voteValue !== null) {
                const lowerBound = nextQuestion.showForRange[0] * 10;
                const upperBound = nextQuestion.showForRange[1] * 10;

                if(props.voteValue > lowerBound && props.voteValue <= upperBound) {
                    //console.log(`Vote value is more than ${lowerBound} and less than or equal to ${upperBound}. Moving to question ${nextQuestionIndex}`);
                    return nextQuestionIndex;
                } else {
                    //console.log('Skipping question', nextQuestionIndex, 'because vote is out of bounds');
                    return getNextQuestion(nextQuestionIndex);
                }
            }

            //console.log('Moving to question', nextQuestionIndex);
            return nextQuestionIndex;
        };

        return getNextQuestion(questionIndex);
    };

    const [state, setState] = useState<QuestionAndAnswerState>(() => ({
        qnaAnimationState: QnAAnimationState.ContentPanelFadeIn,
        questionIndex: resolveNextQuestion() ?? 0,
        questionTime: 0
    }));

    const question = topic?.qa[state.questionIndex ?? 0];
    const questionId = question?.questionId;

    useEffect(() => {
        qnaContainerApi.start({
            opacity: 1,
            config: { duration: 600 },
            onRest: () => {
                setState({
                    ...state,
                    questionTime: Date.now(),
                    qnaAnimationState: QnAAnimationState.TypeTitleText
                });
            }
        });

    }, [state.questionIndex]);

    const submitAnswer: SubmitAnswerFunction = async (answer: string, jump?: number | null) => {
        props.firstQuestionAnswered();

        qnaContainerApi.start({
            opacity: 0,
            config: { duration: 300 },
            onRest: () => {
                questionMediaApi.set({ opacity: 0 });

                setState({
                    ...state,
                    questionIndex: resolveNextQuestion(state.questionIndex, jump) ?? 0,
                    qnaAnimationState: QnAAnimationState.ContentPanelFadeIn
                });
            }
        });

        if (!question) return;
        await axios.post(`${apiUrl}/qna/${topic?.id}`, {
            question: question.question,
            answer: answer,
            vote: props.voteValue,
            voteId: props.voteId,
            timeToAnswer: !state.questionTime ? 0 : Date.now() - state.questionTime,
            preciseCoordinates
        });
    };

    const getQuestionTitle = () => {
        let questionTitle = question?.question;

        const currentLanguage = i18n.language;

        if (currentLanguage !== topic?.defaultLanguage && topic?.altLngs.includes(currentLanguage)) {
            questionTitle = topic?.translations[currentLanguage].qna[questionId ?? 0].question;
        }

        return questionTitle;
    };

    const getMediaUrl = () => {
        if (!question)
            return null;
        if (question.mediaUrl)
            return question.mediaUrl;
        if (question.image)
            return question.image;
        return null;
    };

    const mediaUrl = getMediaUrl();

    const fadeInQna = (question: Question) => {
        setState({ ...state, qnaAnimationState: QnAAnimationState.AnswersFadeIn });
        if(question.answerSctructure === 'dropdown')
            dropdownAnswerApi.start({ opacity: 1, config: { duration: 600 }, });
    };

    return (!question ? null :
        <animated.div style={qnaContainerStyle}>
            <div style={{ margin: '0 0 20px 0' }}>
                <StyledContentPanel>
                    <TypingTextV2
                        active={state.qnaAnimationState === QnAAnimationState.TypeTitleText}
                        delay={10}
                        reset={state.qnaAnimationState === QnAAnimationState.ContentPanelFadeIn}
                        onComplete={() => {
                            if (mediaUrl) {
                                setState({ ...state, qnaAnimationState: QnAAnimationState.MediaFadeIn });
                                questionMediaApi.start({
                                    opacity: 1,
                                    config: { duration: 600 },
                                    onRest: () => {
                                        setState({ ...state, qnaAnimationState: QnAAnimationState.MediaPlay });
                                    }
                                });
                            } else {
                                fadeInQna(question);
                            }
                        }}
                    >
                        {getQuestionTitle()}
                    </TypingTextV2>
                    {!mediaUrl ? null :
                        <animated.div style={{ 
                            marginTop: '20px',
                            ...questionMediaStyle 
                        }}>
                            <DisplayMedia
                                shouldPlay={state.qnaAnimationState === QnAAnimationState.MediaPlay}
                                mediaUrl={mediaUrl}
                                onAfterOneSecond={() => { 
                                    if(state.qnaAnimationState === QnAAnimationState.MediaPlay)
                                        fadeInQna(question);
                                }}
                                onMediaEnded={() => {
                                    if(state.qnaAnimationState === QnAAnimationState.MediaPlay)
                                        fadeInQna(question);
                                }}
                            />
                        </animated.div>
                    }
                    {question.answerSctructure !== 'dropdown' ? null :
                        <animated.div style={{ margin: '40px 0px 20px 0', ...dropdownAnswerStyle }}>
                            <DropdownAnswer question={question.question} answers={question.answers} submitAnswer={submitAnswer} />
                        </animated.div>
                    }
                </StyledContentPanel>
            </div>
            {!(state.qnaAnimationState === QnAAnimationState.AnswersFadeIn || state.qnaAnimationState === QnAAnimationState.QuestionFadeOut) ? null
                : question.answerSctructure === 'dropdown' 
                    ? null
                : question.answerSctructure === 'location_request'
                    ? <RequestLocation question={question.question} answers={question.answers} submitAnswer={submitAnswer} questionId={questionId ?? 0} />
                : question.answerSctructure === 'open'
                    ? <OpenStructure question={question.question} answers={question.answers} submitAnswer={submitAnswer} />
                    : question.answerType === 'multiselect'
                        ? <MultiSelect question={question.question} answers={question.answers} submitAnswer={submitAnswer} questionId={questionId ?? 0} />
                        : question.answerType === 'singleselect'
                            ? <SingleSelect question={question.question} answers={question.answers} submitAnswer={submitAnswer} questionId={questionId ?? 0} />
                            : null
            }
        </animated.div>
    );
};

export default QuestionAndAnswer;