
import React from 'react';
import styled from 'styled-components';
import PulseLoader from "react-spinners/PulseLoader";

const LoadingTopicScreen = styled.div`
    position: fixed;
    background-color: white;
    width: 100vw;
    height: 100vh;
    transition: opacity 1s ease, z-index 1s ease;
    overflow: hidden;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .color-circle-container {
        position: absolute;
        top: calc(50vh - 200vh / 2);
        left: calc(50vw - 200vh / 2);
        width: 200vh;
        height: 200vh;
        border-radius: 50%;
        animation: moveColorCircle 6s linear infinite;
    }

    .inner-color-circle {
        width: 100%;
        height: 100%;
        border-radius: 50%;

        background: conic-gradient(
            #e63939, #ff4d40, #c36eff, #538cff, #53b7ff, #1ed931,
            #53b7ff, #538cff, #c36eff, #ff4d40, #e63939
        );

        filter: blur(150px);

        animation: rotateColorCircle 4s linear infinite;
    }

    .center-title {
        color: white;
        z-index: 999;
        position: absolute;
        display: block;
        left: 0;
        right: 0;
        margin: auto auto;
        text-align: center;
        top: calc(50vh - 180px);

        h1 {
            margin: 0;
            font-size: 29px;
            font-weight: normal;
        }

        h2 {
            margin: 0;
            font-size: 32px;
        }
        h3 {
            font-size: 38px;
        }
    }
      
    @keyframes moveColorCircle {
        0%    { transform: rotate(0deg)   translateX(-30vw) translateX(50vh) rotate(0deg); }
        25%   { transform: rotate(90deg)  translateX(-30vw) translateX(50vh) rotate(-90deg); }
        50%   { transform: rotate(180deg) translateX(-30vw) translateX(50vh) rotate(-180deg); }
        75%   { transform: rotate(270deg) translateX(-30vw) translateX(50vh) rotate(-270deg); }
        100%  { transform: rotate(360deg) translateX(-30vw) translateX(50vh) rotate(-360deg); }
    }

    @keyframes rotateColorCircle {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`;

interface LoadingTopicScreenProps {
    isLoading: boolean
}

const Component: React.FC<LoadingTopicScreenProps> = ({ isLoading }) => {

    return (
        <LoadingTopicScreen style={{
            opacity: isLoading ? 1 : 0,
            zIndex: isLoading ? 1 : -1
        }}>
            <div className='color-circle-container'>
                <div className='inner-color-circle' />
            </div>
            <div className='center-title'>
                <h1>Виж какво</h1>
                <h2>kazva.bg</h2>
                <h3>Зарежда се</h3>
                <PulseLoader
                    color={'#fff'}
                    size={30}
                    speedMultiplier={0.7}
                />
            </div>
        </LoadingTopicScreen>
    )
};

export default Component;